import axios from 'axios'
// import https from 'https'

export const API = axios.create({
	headers: {
		'Content-Type': 'application/json;charset=UTF-8'
	},
	// httpsAgent: new https.Agent({ rejectUnauthorized: false }),
	baseURL: process.env.REACT_APP_API_DOMAIN
})
